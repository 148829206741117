import {EligibilityRequestDataSerializer, EligibilityRequestDetailSerializer, PayerAltNameDetailSerializer} from '../@core/api.service';
import {ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES} from '../@core/eligibility_request_response_status_choices';

type InsuranceEligibilityStatus = keyof typeof ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES;

export class InsuranceEligibility implements EligibilityRequestDetailSerializer {
    id: number;
    patient: number;
    payer_name: PayerAltNameDetailSerializer;
    reject_reason: string;
    request_data: EligibilityRequestDataSerializer;
    response_data: any;
    response_data_html: string;
    response_status: string;
    timestamp: Date | string;
    transaction_id: string;
    user: number;
    alerts: number;

    constructor(data: EligibilityRequestDetailSerializer) {
        if (!data) return;
        Object.assign(this, data);
        this._checkResponseDataHtmlAlerts();
    }

    private _checkResponseDataHtmlAlerts() {
        if (!this.response_data_html) return;

        // Create a temporary DOM parser
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.response_data_html, 'text/html');

        // Count alerts by finding elements with alertLabels class
        this.alerts = doc.querySelectorAll('.alertLabels')?.length;
    }

    get insuranceEligibilityStatus() {
        if (!this.response_status) return null;
        return this._insuranceEligibilityStatus[this.response_status as InsuranceEligibilityStatus];
    }

    private _insuranceEligibilityStatus: {
        [status in keyof typeof ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES]: {
            color: string;
            icon: string;
            normalizedStatus: string;
        }
    } = {
            ACTIVE_COVERAGE: {
                color: 'success',
                icon: 'check-circle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.ACTIVE_COVERAGE,
            },
            INACTIVE_COVERAGE: {
                color: 'danger',
                icon: 'times-circle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.INACTIVE_COVERAGE,
            },
            ERROR: {
                color: 'warning',
                icon: 'exclamation-triangle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.ERROR,
            },
            UNKNOWN: {
                color: 'info',
                icon: 'info-circle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.UNKNOWN,
            },
            OPEN_INQUIRY: {
                color: 'info',
                icon: 'info-circle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.OPEN_INQUIRY,
            },
            HELD_FOR_NAME_MATCHING: {
                color: 'info',
                icon: 'info-circle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.HELD_FOR_NAME_MATCHING,
            },
            SUBSCRIBER_NOT_FOUND: {
                color: 'danger',
                icon: 'times-circle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.SUBSCRIBER_NOT_FOUND,
            },
            UNSPECIFIED_SEE_DETAILS: {
                color: 'info',
                icon: 'info-circle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.UNSPECIFIED_SEE_DETAILS,
            },
            CANNOT_PROCESS: {
                color: 'danger',
                icon: 'exclamation-triangle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.CANNOT_PROCESS,
            },
            PASSED_AS_TEST: {
                color: 'success',
                icon: 'check-circle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.PASSED_AS_TEST,
            },
            DELAYED: {
                color: 'warning',
                icon: 'exclamation-triangle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.DELAYED,
            },
            HELD: {
                color: 'warning',
                icon: 'exclamation-triangle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.HELD,
            },
            MIXED_COVERAGE: {
                color: 'info',
                icon: 'info-circle',
                normalizedStatus: ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES.MIXED_COVERAGE,
            },
        };
}
