export const ELIGIBILITY_REQUEST_RESPONSE_STATUS_CHOICES = {
    OPEN_INQUIRY: 'Open Inquiry',
    HELD_FOR_NAME_MATCHING: 'Held For Name Matching',
    ACTIVE_COVERAGE: 'Active Coverage',
    INACTIVE_COVERAGE: 'Inactive Coverage',
    CANNOT_PROCESS: 'Cannot Process',
    SUBSCRIBER_NOT_FOUND: 'Subscriber Not Found',
    UNSPECIFIED_SEE_DETAILS: 'Unspecified-See Details',
    PASSED_AS_TEST: 'Passed as test',
    DELAYED: 'Delayed',
    HELD: 'Held',
    MIXED_COVERAGE: 'Mixed Coverage',
    UNKNOWN: 'Unknown',
    ERROR: 'Error',
};
