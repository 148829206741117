import {Injectable} from '@angular/core';

type HostKey = 'ARSANA' | 'WASHSENSE' | 'HELIX' | 'CCMDACO' | 'GUARDIAN' | 'SOLUTIONS_LAB' | 'CORELAB' | 'IMPACT_MEDICAL';

interface HostConfig {
    host: HostKey;
    hostSearch: string;
    siteName: string;
    domainText: string;
    loginUserLabel?: string;
    contactSupportTextHtml?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    host: HostKey = 'ARSANA';
    siteName = 'Arsana';
    domainText = 'portal.arsanahealth.com';
    loginUserLabel = 'E-mail address';
    contactSupportTextHtml = `Please reach out to <a href="mailto:support@arsanahealth.com">support@arsanahealth.com</a>.`;

    hostConfigs: HostConfig[] = [
        {
            host: 'HELIX',
            hostSearch: 'helix',
            siteName: 'Helix',
            domainText: 'helix.arsanahealth.com',
        },
        {
            host: 'CCMDACO',
            hostSearch: 'ccmdaco',
            siteName: 'CareConnectMD ACO',
            domainText: 'ccmdaco.arsanahealth.com',
        },
        {
            host: 'GUARDIAN',
            hostSearch: 'guardian',
            siteName: 'Guardian - Arsana',
            domainText: 'guardian.arsanahealth.com',
        },
        {
            host: 'SOLUTIONS_LAB',
            hostSearch: 'solutionslab',
            siteName: 'Solutions Lab - Arsana',
            domainText: 'solutionslab.arsanahealth.com',
        },
        {
            host: 'WASHSENSE',
            hostSearch: 'washsense',
            siteName: 'WashSense',
            domainText: 'portal.washsense.com or portal.arsanahealth.com or portal.arsana.io',
        },
        {
            host: 'CORELAB',
            hostSearch: 'corelab',
            siteName: 'Core Analytics - Arsana',
            domainText: 'corelab.arsanahealth.com',
            loginUserLabel: 'Username',
            contactSupportTextHtml:
                `Contact: <a href="mailto:info@corelabandxray.com">info@corelabandxray.com</a>
                <br>(818) 906-2614`,
        },
        {
            host: 'IMPACT_MEDICAL',
            hostSearch: 'impactmedkc',
            siteName: 'Impact Medical KC - Arsana',
            domainText: 'impactmedkc.arsanahealth.com',
        },
    ];

    constructor() {
        const config = this.hostConfigs.find(x => window.location.host.includes(x.hostSearch));
        if (config) Object.assign(this, config);
    }

    processTitle(title: string): string {
        if (title) title += ' - ';
        title += this.siteName;
        return title;
    }
}
