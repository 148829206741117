import {Component, Input} from '@angular/core';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-status-label',
    templateUrl: 'status-label.component.html',
    styleUrls: ['./status-label.component.scss'],
})
export class StatusLabelComponent extends NgbTooltip {
    @Input() color: string;
    @Input() colorClass: string;
    @Input() label: string;
    @Input() strong: boolean;
    @Input() user: string;
    @Input() set tooltip(tooltip) {
        this.ngbTooltip = tooltip;
    }
}
